import React, { Component } from "react";
import CSVReader from "react-csv-reader";
import firebase from "firebase/compat/app";

import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalCard } from "bloomer/lib/components/Modal/Card/ModalCard";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import times from "../svgs/ic-24-close.svg";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Columns, Column, Button } from "bloomer";
import MyTable from "../components/admin/MyTable";
import { Container } from "bloomer/lib/layout/Container";
import { withAdminCtx } from "../contexts/AdminContext";
import { withRouter } from "react-router-dom";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withWareCtx } from "../contexts/WarehouseContext";
import AdminNavbar from "../components/admin/AdminNavbar";
import { removeSizeProps } from "bloomer/lib/grid/grid";
import { DateRangePicker } from "react-dates";
import DButtonCSV from "../components/DButtonCSV";
import { Flex } from "@chakra-ui/react";
import Loader from "../components/Loader";

var moment = require("moment");

class DReportCaricamento extends Component {
  state = {
    loadingCSV: false,
    loadingCSVGiacenze: false,
    loadingCSVProdotti: false,
    loadingCSVCoupon: false,
    loadingCSVAmazonFiscale: false,
    riepilogoModalActive: false,
    riepilogoModalGiacenzeActive: false,
    riepilogoProdottiActive: false,
    riepilogoModalCouponActive: false,
    riepilogoModalAmazonFiscaleActive: false,
    fiscaleModalNotFound: false,
  };

  constructor(props) {
    super(props);
    this.transformVendor = this.transformVendor.bind(this);
    this.transformAmzGiacenze = this.transformAmzGiacenze.bind(this);
  }

  async componentDidMount() {
    const prodottiPivotTable = [];
    const productData = await firebase.firestore().collection("reports_products").get();

    productData.forEach((p) => {
      prodottiPivotTable.push(p.data());
    });

    this.setState({ prodottiPivotTable });

    const country = [];
    const countryData = await firebase.firestore().collection("nations").get();

    countryData.forEach((c) => {
      country[c.data().uid] = {
        uid: c.data().uid,
        description: c.data().description,
        translation: c.data().translation || "",
      };
    });

    this.setState({ country });
  }

  replaceAmazonGiacenzeHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_");

    return header;
  }

  renderCSVGiacenzeLoader() {
    return (
      <div
        style={{
          backgroundColor: "#75759047",
          padding: "20px",
          marginBottom: "5px",
        }}
      >
        <h3 className="title has-text-black">REPORT GIACENZE AMAZON</h3>
        {this.state.prodottiPivotTable ? (
          <>
            <Flex gap={2}>
              <DButtonCSV
                title="CARICA GIACENZE AMZ"
                columns={[
                  {
                    Header: "ID",
                    accessor: "uid",
                  },
                  {
                    Header: "Brand",
                    accessor: "brand",
                  },
                  {
                    Header: "Country",
                    accessor: "country",
                  },
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "Amz_sku",
                    accessor: "seller_sku",
                  },
                  {
                    Header: "Quantity",
                    accessor: "quantity_available",
                  },
                ]}
                uid="uid"
                update
                table="products"
                transform={this.transformAmzGiacenze}
                replaceHeader={this.replaceAmazonGiacenzeHeader}
                updateField={(row) => {
                  const field = row.country ? "amazon_quantity_" + row.country : "amazon_quantity";
                  return {
                    uid: row.uid,
                    [field]: row.quantity_available,
                  };
                }}
              />

              <DButtonCSV
                title="CARICA VENDITE VENDOR"
                columns={[
                  {
                    Header: "Data",
                    accessor: "date",
                  },
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "ASIN",
                    accessor: "asin",
                  },
                  {
                    Header: "Ordinate",
                    accessor: "unitaOrdinate",
                  },
                ]}
                uid="id"
                // update
                table="reports_vendor"
                transform={(r) => this.transformVendor(r, true)}
                replaceHeader={this.replaceHeaderVendorVendite}
              />
            </Flex>
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
  renderCSVVendorLoader() {
    return (
      <div
        style={{
          backgroundColor: "#75759047",
          padding: "20px",
          marginBottom: "5px",
        }}
      >
        <h3 className="title has-text-black">REPORT GIACENZE AMAZON</h3>
        {this.state.prodottiPivotTable ? (
          <Flex flexDirection={"column"} gap={2}>
            <Flex gap={2}>
              <DButtonCSV
                title="CARICA GIACENZE VENDOR ITA"
                columns={[
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "Ordini aperti",
                    accessor: "vendor_ordini_aperti",
                  },
                  {
                    Header: "Vendibili",
                    accessor: "vendor_vendibili",
                  },
                ]}
                uid="uid"
                update
                table="products"
                transform={this.transformVendor}
                replaceHeader={this.replaceHeaderVendor}
              />
              <DButtonCSV
                title="CARICA GIACENZE VENDOR FRANCIA"
                columns={[
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "Ordini aperti",
                    accessor: "vendor_ordini_aperti_fr",
                  },
                  {
                    Header: "Vendibili",
                    accessor: "vendor_vendibili_fr",
                  },
                ]}
                uid="uid"
                update
                table="products"
                transform={this.transformVendor}
                replaceHeader={(h) => this.replaceHeaderVendor(h, "fr")}
              />
            </Flex>
            <Flex gap={2}>
              <DButtonCSV
                title="CARICA GIACENZE VENDOR SPAGNA"
                columns={[
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "Ordini aperti",
                    accessor: "vendor_ordini_aperti_es",
                  },
                  {
                    Header: "Vendibili",
                    accessor: "vendor_vendibili_es",
                  },
                ]}
                uid="uid"
                update
                table="products"
                transform={this.transformVendor}
                replaceHeader={(h) => this.replaceHeaderVendor(h, "es")}
              />
              <DButtonCSV
                title="CARICA GIACENZE VENDOR GERMANIA"
                columns={[
                  {
                    Header: "SKU",
                    accessor: "sku",
                  },
                  {
                    Header: "Ordini aperti",
                    accessor: "vendor_ordini_aperti_de",
                  },
                  {
                    Header: "Vendibili",
                    accessor: "vendor_vendibili_de",
                  },
                ]}
                uid="uid"
                update
                table="products"
                transform={this.transformVendor}
                replaceHeader={(h) => this.replaceHeaderVendor(h, "de")}
              />
            </Flex>
          </Flex>
        ) : (
          <Loader />
        )}
      </div>
    );
  }

  getCountry(prod, r) {
    if (prod.amazon_it === r.seller_sku) {
      return "it";
    } else if (
      prod.amazon_de === r.seller_sku ||
      prod.amazon_es === r.seller_sku ||
      prod.amazon_fr === r.seller_sku ||
      prod.amazon_nl === r.seller_sku ||
      prod.amazon_be === r.seller_sku ||
      prod.amazon_pl === r.seller_sku ||
      prod.amazon_se === r.seller_sku ||
      prod.amazon_uk === r.seller_sku
    )
      return r.seller_sku.substring(r.seller_sku.length - 2);
    else return null;
  }

  checkAmazonCountries(prod, r) {
    return (
      prod.amz_prime === r.seller_sku ||
      prod.amazon_it === r.seller_sku ||
      prod.amazon_de === r.seller_sku ||
      prod.amazon_es === r.seller_sku ||
      prod.amazon_fr === r.seller_sku ||
      prod.amazon_nl === r.seller_sku ||
      prod.amazon_be === r.seller_sku ||
      prod.amazon_pl === r.seller_sku ||
      prod.amazon_se === r.seller_sku ||
      prod.amazon_uk === r.seller_sku
    );
  }
  transformAmzGiacenze(r, vendite = false) {
    console.log("AA prodottiPivotTable", this.state.prodottiPivotTable);
    const prodotti = this.state.prodottiPivotTable;
    const riepilogoDataGiacenze = [];

    prodotti.forEach((prod) => {
      if (this.checkAmazonCountries(prod, r)) {
        r.country = this.getCountry(prod, r);
        console.log("AA country", r.country, r.seller_sku);
        r.sku = prod.uid;
        this.props.admin.products.products
          .filter((p) => p.active === true)
          .forEach((pp) => {
            if (r.seller_sku == "Carbone3x-ama") console.log("aa ppsku", pp.sku, r.sku);
            if (pp.sku === r.sku && pp.brand != "MyRebranding") {
              r.uid = pp.uid;
              r.brand = pp.brand;
            } else if (!!pp.codiciFigli) {
              console.log("zzz codici", pp.codiciFigli.length, pp);
              pp.codiciFigli.forEach((c) => {
                if (c.sku == r.sku) {
                  r.parentSku = pp.sku;
                  r.parentUid = pp.uid;
                }
              });
            }
          });
      }
    });
    if (r.warehouse_condition_code !== "SELLABLE") r.discard = true;

    return r;
  }

  transformVendor(r, vendite = false) {
    const prodotti = this.state.prodottiPivotTable;
    let filteredData = [];
    console.log("AA prodotti", prodotti);
    prodotti.forEach((prod) => {
      if (prod.amz_vendor === r.asin) {
        r.sku = prod.uid;

        this.props.admin.products.products
          .filter((p) => p.active === true)
          .forEach((pp) => {
            if (pp.sku === r.sku) {
              r.uid = pp.uid;
            }
          });
      }
    });
    console.log("VV uid", r.uid, r);

    if (vendite) {
      r.id = r.sku + r.date.replace(/\W/g, "_");
      r.purchase_date = moment(r.date, "DD/MM/YYYY").toDate();
    }
    console.log("VV uid post", r.uid, r);

    return r;
  }

  replaceHeaderVendor(header, country) {
    if (country) {
      console.log("AA HEADER", header, country);
      if (header === "open_purchase_order_quantity") header = "vendor_ordini_aperti_" + country;
      if (header === "sellable_on_hand_units") header = "vendor_vendibili_" + country;
    } else {
      if (header === "quantit__di_ordini_d_acquisto_aperti") header = "vendor_ordini_aperti";
      if (header === "unit__disponibili_vendibili") header = "vendor_vendibili";
    }
    return header;
  }

  replaceHeaderVendorVendite(header) {
    console.log("AA HEADER"), header;
    if (header === "unit__ordinate") header = "unitaOrdinate";
    return header;
  }

  replacCouponHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_");
    if (header === "coupon") header = "uid";
    if (header === "slug_categoria_coupon") header = "categoria";

    return header;
  }

  renderCSVCouponLoader() {
    const csvOptionsCoupon = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replacCouponHeader(header),
    };

    return (
      <div
        style={{
          backgroundColor: "#75759047",
          padding: "20px",
          marginBottom: "5px",
        }}
      >
        <h3 className="title has-text-black">REPORT CATEGORIE COUPON</h3>
        <h1>Carica Coupon CSV</h1>
        <CSVReader
          parserOptions={csvOptionsCoupon}
          onFileLoaded={(data, fileInfo) => {
            console.dir(data, fileInfo);
            this.insertCsvCoupon(data);
          }}
        />
      </div>
    );
  }

  async insertCsvCoupon(data) {
    this.setState({
      riepilogoDataCoupon: data,
      riepilogoModalCouponActive: true,
    });
  }

  renderRiepilogCouponModal() {
    const { riepilogoDataCoupon, loadingCSVCoupon, insertedRow } = this.state;
    console.log("AA Riepil", riepilogoDataCoupon);
    const columns = [
      {
        Header: "Coupon",
        accessor: "uid",
      },
      {
        Header: "Categoria",
        accessor: "categoria",
      },
    ];
    return (
      <div>
        <Modal isActive={this.state.riepilogoModalCouponActive} className="laboratorio-modal combinations-modal">
          <ModalCard
            style={{
              width: "1200px",
              maxWidth: "1200px",
            }}
          >
            <ModalCardHeader>
              <Columns style={{ width: "100%" }} isMobile>
                <Column isSize="3/4">
                  <h3 className="title has-text-white" style={{ fontSize: "20px" }}>
                    Riepilogo
                  </h3>
                </Column>
                <Column>
                  <img
                    src={times}
                    className="left-header-button is-pulled-right"
                    onClick={() => this.setState({ riepilogoModalCouponActive: false })}
                    alt=""
                  />
                </Column>
              </Columns>
            </ModalCardHeader>

            <ModalCardBody>
              <Columns>
                <Column>
                  <Button
                    isLoading={loadingCSVCoupon}
                    className="primary"
                    onClick={() => this.loadCSVCoupon(riepilogoDataCoupon)}
                  >
                    CARICA
                  </Button>
                </Column>
                <Column>
                  {loadingCSVCoupon && (
                    <h1>
                      caricati {insertedRow} di {riepilogoDataCoupon.length}
                    </h1>
                  )}
                </Column>
              </Columns>

              <MyTable data={riepilogoDataCoupon} columns={columns} hideButtons {...this.props} />
            </ModalCardBody>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  async loadCSVCoupon(data) {
    this.setState({ loadingCSVCoupon: true });
    console.log("AA row");
    let index = 0;
    const promises = [];
    data.forEach(async (row) => {
      if (!!row.uid) {
        promises.push(
          new Promise(async (r, rj) => {
            try {
              await firebase.firestore().collection("coupon_categories").doc(row.uid).set(row);

              index++;

              this.setState({ insertedRow: index });
              r(index);
            } catch (err) {
              console.log("AA err", err);

              rj(err);
            }
          }),
        );
      }
    });

    await Promise.all(promises);

    this.setState({
      riepilogoModalCouponActive: false,
      loadingCSVCoupon: false,
    });
  }

  async insertCsvAmazonFiscale(data) {
    data.forEach((d) => {
      d.activity_transaction_id = d.activity_transaction_id
        .toString()
        .replaceAll(":/", "_")
        .replaceAll("/", "_")
        .replaceAll(":", "_");
    });

    this.setState({
      riepilogoDataAmazonFiscale: data,
      riepilogoModalAmazonFiscaleActive: true,
    });
  }

  renderRiepilogAmazonFiscaleModal() {
    const { riepilogoDataAmazonFiscale, loadingCSVAmazonFiscale, insertedRow } = this.state;
    console.log("AA Riepil", riepilogoDataAmazonFiscale);
    const columns = [
      {
        Header: "Tipo",
        accessor: "transaction_type",
      },
      {
        Header: "Order",
        accessor: "order_id",
      },
      {
        Header: "Sku",
        accessor: "sku",
      },
      {
        Header: "Prezzo",
        accessor: "total_price_of_items_amt_vat_incl",
      },
      {
        Header: "Spedizione",
        accessor: "total_ship_charge_amt_vat_incl",
      },
      {
        Header: "Regalo",
        accessor: "total_gift_wrap_amt_vat_incl",
      },
    ];
    return (
      <div>
        <Modal isActive={this.state.riepilogoModalAmazonFiscaleActive} className="laboratorio-modal combinations-modal">
          <ModalCard
            style={{
              width: "1200px",
              maxWidth: "1200px",
            }}
          >
            <ModalCardHeader>
              <Columns style={{ width: "100%" }} isMobile>
                <Column isSize="3/4">
                  <h3 className="title has-text-white" style={{ fontSize: "20px" }}>
                    Riepilogo
                  </h3>
                </Column>
                <Column>
                  <img
                    src={times}
                    className="left-header-button is-pulled-right"
                    onClick={() =>
                      this.setState({
                        riepilogoModalAmazonFiscaleActive: false,
                      })
                    }
                    alt=""
                  />
                </Column>
              </Columns>
            </ModalCardHeader>

            <ModalCardBody>
              <Columns>
                <Column>
                  <Button
                    isLoading={loadingCSVAmazonFiscale}
                    className="primary"
                    onClick={() => this.loadCSVAmazonFiscale(riepilogoDataAmazonFiscale)}
                  >
                    CARICA
                  </Button>
                </Column>
                <Column>
                  {loadingCSVAmazonFiscale && (
                    <h1>
                      caricati {insertedRow} di {riepilogoDataAmazonFiscale.length}
                    </h1>
                  )}
                </Column>
              </Columns>

              <MyTable data={riepilogoDataAmazonFiscale} columns={columns} hideButtons {...this.props} />
            </ModalCardBody>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  async loadCSVAmazonFiscale(data) {
    this.setState({ loadingCSVAmazonFiscale: true });

    let index = 0;
    const promises = [];
    const docsNotFound = [];
    data.forEach((row) => {
      promises.push(
        new Promise(async (r, rj) => {
          try {
            const type = row.transaction_type;
            const piattaforma2 = row.transportation_mode === null ? "VENDOR" : "PRIME";
            let uid = "amazon_" + piattaforma2 + "_" + row.order_id + "_" + row.sku.toLowerCase().replace(/\W/g, "_");
            // console.log("ZZ type", type);

            row.purchase_date = moment(row.purchase_date, "DD/MM/YYYY").toDate();
            row.payments_date = row.purchase_date;
            row.piattaforma = "AMAZON";
            row.piattaforma_2 = piattaforma2;

            if (type === "SALE") {
              console.log("ZZ type", type);

              try {
                await firebase.firestore().collection("reports_kpi").doc(uid).update({
                  purchase_date: row.purchase_date,
                  payments_date: row.purchase_date,
                  transaction_type: row.transaction_type,
                  total_price_of_items_amt_vat_incl: row.total_price_of_items_amt_vat_incl,
                  total_gift_wrap_amt_vat_incl: row.total_gift_wrap_amt_vat_incl,
                  total_ship_charge_amt_vat_incl: row.total_ship_charge_amt_vat_incl,
                  price_of_items_vat_rate_percent: row.price_of_items_vat_rate_percent,
                });
              } catch (err) {
                console.log("VV not found", row.order_id, uid);

                row.uid = uid;
                docsNotFound.push(row);
              }
            }

            if (type === "RETURN" || type === "REFUND") {
              const pData = await firebase.firestore().collection("reports_kpi").doc(uid).get();

              uid += "_" + row.activity_transaction_id + "_" + type;
              console.log("zz type", type);

              if (pData.exists) {
                row.buyer_name = pData.data().buyer_name;
                row.cap = pData.data().cap;
                // row.country = pData.data().country;
                row.country_code = pData.data().country_code;

                console.log("zz buyer_name", row.buyer_name, pData.data());
              }

              row.uid = uid;
              await firebase.firestore().collection("reports_kpi").doc(uid).set(row);
            }
            index++;
            this.setState({ insertedRow: index });
            r(index);
          } catch (err) {
            console.log("AA err", err);
            rj(err);
          }
        }),
      );
    });

    await Promise.all(promises);

    if (docsNotFound.length > 0) {
      this.setState({ fiscaleModalNotFound: true, docsNotFound });
    }
    this.setState({
      riepilogoModalAmazonFiscaleActive: false,
      loadingCSVAmazonFiscale: false,
    });
  }

  renderFiscaleModalNotFound() {
    const { docsNotFound, fiscaleModalNotFound } = this.state;
    const columns = [
      {
        Header: "UID",
        accessor: "order_id",
      },
    ];
    return (
      <div>
        <Modal isActive={fiscaleModalNotFound} className="laboratorio-modal combinations-modal">
          <ModalCard
            style={{
              width: "1200px",
              maxWidth: "1200px",
            }}
          >
            <ModalCardHeader>
              <Columns style={{ width: "100%" }} isMobile>
                <Column isSize="3/4">
                  <h3 className="title has-text-white" style={{ fontSize: "20px" }}>
                    Documenti non trovati
                  </h3>
                </Column>
                <Column>
                  <img
                    src={times}
                    className="left-header-button is-pulled-right"
                    onClick={() => this.setState({ fiscaleModalNotFound: false })}
                    alt=""
                  />
                </Column>
              </Columns>
            </ModalCardHeader>

            <ModalCardBody>
              <MyTable data={docsNotFound} columns={columns} hideButtons {...this.props} />
            </ModalCardBody>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  getPriceFormatted(p) {
    if (!p) return "";
    // console.log("aa p", p);
    return parseFloat(p.toString().replace("€", "").replace(",", "."));
  }

  getAliQuantity(name) {
    const word = "【";
    const index = name.indexOf(word) + word.length;
    const qt = name.substring(index, index + 1);
    return parseInt(qt);
  }

  async loadCSV(data) {
    this.setState({ loadingCSV: true });
    let index = 0;
    const promises = [];
    data.forEach(async (row) => {
      console.log("AA row", row);

      promises.push(
        new Promise(async (r, rj) => {
          try {
            await firebase.firestore().collection("reports_kpi").doc(row.uid).set(row);

            index++;

            this.setState({ insertedRow: index });
            r(index);
          } catch (err) {
            console.log("AA err", err);

            rj(err);
          }
        }),
      );
    });

    await Promise.all(promises);

    this.setState({ riepilogoModalActive: false, loadingCSV: false });
  }

  renderRiepilogoModal() {
    const { riepilogoData, loadingCSV, insertedRow } = this.state;
    console.log("AA Riepil", riepilogoData);
    const columns = [
      {
        Header: "Order id",
        accessor: "order_id",
      },
      {
        Header: "Cliente",
        accessor: "buyer_name",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Prezzo",
        accessor: "item_price",
      },
      {
        Header: "Data",
        accessor: "purchase_date",
        type: "dateString",
      },
      {
        Header: "Prodotto",
        accessor: "sku",
      },
      {
        Header: "Qty",
        accessor: "quantity_shipped",
      },
      {
        Header: "Piattaforma",
        accessor: "piattaforma",
      },
      {
        Header: "Cap",
        accessor: "cap",
      },
      {
        Header: "Nazione",
        accessor: "country",
      },
      {
        Header: "Nazione Cod",
        accessor: "country_code",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ];
    return (
      <div>
        <Modal isActive={this.state.riepilogoModalActive} className="laboratorio-modal combinations-modal">
          <ModalCard
            style={{
              width: "1200px",
              maxWidth: "1200px",
            }}
          >
            <ModalCardHeader>
              <Columns style={{ width: "100%" }} isMobile>
                <Column isSize="3/4">
                  <h3 className="title has-text-white" style={{ fontSize: "20px" }}>
                    Riepilogo
                  </h3>
                </Column>
                <Column>
                  <img
                    src={times}
                    className="left-header-button is-pulled-right"
                    onClick={() => this.setState({ riepilogoModalActive: false })}
                    alt=""
                  />
                </Column>
              </Columns>
            </ModalCardHeader>

            <ModalCardBody>
              <Columns>
                <Column>
                  <Button isLoading={loadingCSV} className="primary" onClick={() => this.loadCSV(riepilogoData)}>
                    CARICA
                  </Button>
                </Column>
                <Column>
                  {loadingCSV && (
                    <h1>
                      caricati {insertedRow} di {riepilogoData.length}
                    </h1>
                  )}
                </Column>
              </Columns>

              <MyTable data={riepilogoData} columns={columns} hideButtons {...this.props} />
            </ModalCardBody>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  renderRiepilogProdottiModal() {
    const { riepilogoDataProdotti, loadingCSVProdotti, insertedRow } = this.state;
    console.log("AA Riepil", riepilogoDataProdotti);
    const columns = [
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "AMZ VENDITORE",
        accessor: "amz_noi",
      },
      {
        Header: "AMZ PRIME",
        accessor: "amz_prime",
      },
      {
        Header: "IVA",
        accessor: "iva",
      },
    ];
    return (
      <div>
        <Modal isActive={this.state.riepilogoProdottiActive} className="laboratorio-modal combinations-modal">
          <ModalCard
            style={{
              width: "1200px",
              maxWidth: "1200px",
            }}
          >
            <ModalCardHeader>
              <Columns style={{ width: "100%" }} isMobile>
                <Column isSize="3/4">
                  <h3 className="title has-text-white" style={{ fontSize: "20px" }}>
                    Riepilogo
                  </h3>
                </Column>
                <Column>
                  <img
                    src={times}
                    className="left-header-button is-pulled-right"
                    onClick={() => this.setState({ riepilogoProdottiActive: false })}
                    alt=""
                  />
                </Column>
              </Columns>
            </ModalCardHeader>

            <ModalCardBody>
              <Columns>
                <Column>
                  <Button
                    isLoading={loadingCSVProdotti}
                    className="primary"
                    onClick={() => this.loadCSVProdotti(riepilogoDataProdotti)}
                  >
                    CARICA
                  </Button>
                </Column>
                <Column>
                  {loadingCSVProdotti && (
                    <h1>
                      caricati {insertedRow} di {riepilogoDataProdotti.length}
                    </h1>
                  )}
                </Column>
              </Columns>

              <MyTable data={riepilogoDataProdotti} columns={columns} hideButtons {...this.props} />
            </ModalCardBody>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  async insertCsvAmazon(data) {
    const riepilogoData = [];
    console.log("AA data", data);

    data.forEach(async (r) => {
      if (r.item_price && r.purchase_date) {
        r.piattaforma = "AMAZON";
        r.piattaforma_2 = !!r.order_item_id ? "VENDOR" : "PRIME";

        r.uid = "amazon_" + r.piattaforma_2 + "_" + r.order_id + "_" + r.sku.toLowerCase().replace(/\W/g, "_");
        r.cliente = r.buyer_name;
        r.buyer_name = !!r.recipient_name ? r.recipient_name.toString().toLowerCase() : "-";
        if (!r.quantity_shipped) {
          r.quantity_shipped = r.quantity || r.quantity_purchased;
        }
        riepilogoData.push(r);
      }
    });
    console.log("AA riepilogoData", riepilogoData);

    this.setState({ riepilogoData, riepilogoModalActive: true });
  }

  async insertCsvEbay(data) {
    const riepilogoData = [];
    const totali = [];
    data.forEach(async (r) => {
      if (r.item_price && !!r.sku) {
        r.piattaforma = "EBAY";
        r.uid = "ebay_" + r.order_id + "_" + r.sku;
        r.buyer_name = !!r.buyer_name ? r.buyer_name.toLowerCase() : "";
        r.email = !!r.email && r.email.length > 0 ? r.email : r.nome_utente_dell_acquirente;
        r.purchase_date = moment(r.purchase_date, "DD/MMM/YYYY").toDate();
        r.spedizione_e_imballaggio = this.getPriceFormatted(r.spedizione_e_imballaggio);
        r.item_price = this.getPriceFormatted(r.item_price);

        riepilogoData.push(r);
      } else if (!r.sku) {
        totali.push(r);
      }
    });
    console.log("AA totali", totali);

    totali.forEach((t) => {
      riepilogoData.forEach((d) => {
        if (d.order_id === t.order_id) {
          d.buyer_name = !!t.buyer_name ? t.buyer_name.toLowerCase() : "";

          d.spedizione_e_imballaggio = this.getPriceFormatted(t.spedizione_e_imballaggio);
          d.metodo_di_pagamento = t.metodo_di_pagamento;
          d.servizio_di_spedizione = t.servizio_di_spedizione;
          d.nome_utente_dell_acquirente = t.nome_utente_dell_acquirente;
          d.email = t.email;
          d.nota_dell_acquirente = t.nota_dell_acquirente;
          d.indirizzo_dell_acquirente_1 = t.indirizzo_dell_acquirente_1;
          d.indirizzo_dell_acquirente_2 = t.indirizzo_dell_acquirente_2;
          d.indirizzo_di_spedizione_1 = t.indirizzo_di_spedizione_1;
          d.indirizzo_di_spedizione_2 = t.indirizzo_di_spedizione_2;
          d.paese_di_spedizione = t.paese_di_spedizione;
          d.provincia_dell_acquirente = t.provincia_dell_acquirente;
          d.provincia_di_spedizione = t.provincia_di_spedizione;
          d.telefono_destinatario = t.telefono_destinatario;
          d.nome_destinatario = t.nome_destinatario;
          d.cap = t.cap;
          d.cap_dell_acquirente = t.cap_dell_acquirente;
          d.citt__dell_acquirente = t.citt__dell_acquirente;
          d.citt__di_spedizione = t.citt__di_spedizione;
          d.country = t.country;
          d.country = t.country;

          d.email = !!d.email && d.email.length > 0 ? d.email : d.nome_utente_dell_acquirente;
        }
      });
    });

    this.setState({ riepilogoData, riepilogoModalActive: true });
  }

  async insertCsvWoo(data) {
    const riepilogoData = [];
    console.log("AA insert", data);

    data.forEach(async (r) => {
      if (r.item_price || r.order_status === "Rimborsato") {
        r.piattaforma = "WOO";
        r.uid = "woo_" + r.item_id + "_" + r.sku;
        if (r.order_status === "Rimborsato" && !r.item_price) r.uid = "woo_" + r.order_id + "_rimborso";
        r.buyer_name = r.first_name__shipping_.toLowerCase() + " " + r.last_name__shipping_.toLowerCase();
        r.purchase_date = moment(r.purchase_date).toDate();
        console.log("AA insert", r);
        riepilogoData.push(r);
      }
    });
    this.setState({ riepilogoData, riepilogoModalActive: true });
  }

  async insertCsvAli(data) {
    const { country } = this.state;
    const riepilogoData = [];
    console.log("AA insert", data, country);

    const countryList = [];
    Object.keys(country).forEach(function (cod) {
      const c = country[cod];

      countryList.push(c);
    });

    data.forEach(async (r) => {
      if (r.item_price) {
        r.piattaforma = "ALIEXPRESS";
        r.uid = "aliexpress_" + r.order_id + "_" + r.sku;
        r.purchase_date = moment(r.purchase_date).toDate();
        r.item_price = this.getPriceFormatted(r.item_price);
        r.shipping_cost = this.getPriceFormatted(r.shipping_cost);

        r.quantity_shipped = this.getAliQuantity(r.product_information);
        const countryListFiltered = countryList.filter((c) => c.translation === r.buyer_country);
        console.log("AA countryList", countryListFiltered);

        if (countryListFiltered.length > 0) r.country_code = countryListFiltered[0].uid;

        console.log("AA insert", r);
        riepilogoData.push(r);
      }
    });
    this.setState({ riepilogoData, riepilogoModalActive: true });
  }

  renderCSVLoader() {
    const csvOptionsAmazon = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replaceAmazonHeader(header),
    };
    const csvOptionsAmazonFiscale = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replaceAmazonFiscaleHeader(header),
    };
    const csvOptionsEbay = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replaceEbayHeader(header),
    };
    const csvOptionsWoo = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replaceWooHeader(header),
    };
    const csvOptionsAli = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => this.replaceAliHeader(header),
    };

    return (
      <div>
        <div
          style={{
            backgroundColor: "#75759047",
            padding: "20px",
            marginBottom: "5px",
          }}
        >
          <h3 className="title has-text-black">REPORTS VENDITE</h3>
          <Columns>
            <Column>
              <h1>Amazon</h1>
              <CSVReader
                parserOptions={csvOptionsAmazon}
                onFileLoaded={(data, fileInfo) => {
                  console.log("AA loaded", data, fileInfo);
                  this.insertCsvAmazon(data);
                }}
              />
            </Column>
            <Column>
              <h1>eBay</h1>
              <CSVReader
                parserOptions={csvOptionsEbay}
                onFileLoaded={(data, fileInfo) => {
                  console.dir(data, fileInfo);
                  this.insertCsvEbay(data);
                }}
              />
            </Column>
            <Column>
              <h1>wooCommerce</h1>
              <CSVReader
                parserOptions={csvOptionsWoo}
                onFileLoaded={(data, fileInfo) => {
                  console.dir(data, fileInfo);
                  this.insertCsvWoo(data);
                }}
              />
            </Column>
          </Columns>
          <Columns>
            <Column>
              <h1>Amazon Fiscale</h1>
              <CSVReader
                parserOptions={csvOptionsAmazonFiscale}
                onFileLoaded={(data, fileInfo) => {
                  console.dir(data, fileInfo);
                  this.insertCsvAmazonFiscale(data);
                }}
              />
            </Column>
            <Column>
              <h1>Aliexpress</h1>
              <CSVReader
                parserOptions={csvOptionsAli}
                onFileLoaded={(data, fileInfo) => {
                  console.dir(data, fileInfo);
                  this.insertCsvAli(data);
                }}
              />
            </Column>
            <Column></Column>
          </Columns>
        </div>
        <div
          style={{
            backgroundColor: "#75759047",
            padding: "20px",
            marginBottom: "5px",
          }}
        >
          <h3 className="title has-text-black">TABELLA PRODOTTI MYLAB</h3>
          <DButtonCSV
            title="CARICA PRODOTTI"
            columns={[
              {
                Header: "UID",
                accessor: "uid",
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Brand",
                accessor: "brand",
              },
              {
                Header: "AMZ NOI",
                accessor: "amz_noi",
              },
              {
                Header: "AMZ PRIME",
                accessor: "amz_prime",
              },
              {
                Header: "AMZ VENDOR",
                accessor: "amz_vendor",
              },
              {
                Header: "IVA",
                accessor: "iva",
              },
              // {
              //   Header: "Omaggio",
              //   accessor: "omaggio"
              // },
              // {
              //   Header: "Coupon",
              //   accessor: "coupon"
              // },
              {
                Header: "AMZ FR",
                accessor: "amazon_fr",
              },
              {
                Header: "IVA FR",
                accessor: "iva_fr",
              },
              {
                Header: "AMZ DE",
                accessor: "amazon_de",
              },
              {
                Header: "IVA DE",
                accessor: "iva_de",
              },
              {
                Header: "AMZ ES",
                accessor: "amazon_es",
              },
              {
                Header: "IVA ES",
                accessor: "iva_es",
              },
              {
                Header: "AMZ NL",
                accessor: "amazon_nl",
              },
              {
                Header: "IVA NL",
                accessor: "iva_nl",
              },
            ]}
            uid="uid"
            table="reports_products"
            replaceHeader={this.replaceProdottiHeader}
          />
        </div>
      </div>
    );
  }

  replaceAmazonHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_");
    if (header === "amazon_order_id") header = "order_id";
    if (header === "ship_postal_code") header = "cap";
    if (header === "ship_country") header = "country_code";
    if (header === "buyer_email") header = "email";
    return header;
  }
  replaceAmazonFiscaleHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_");
    if (header === "seller_sku") header = "sku";
    if (header === "item_description") header = "product_name";
    if (header === "transaction_complete_date") header = "purchase_date";
    if (header === "qty") header = "quantity_shipped";
    if (header === "transaction_event_id") header = "order_id";

    return header;
  }

  replaceEbayHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_").replace(" ", "_");
    if (header === "numero_ordine") header = "order_id";
    if (header === "data_vendita") header = "purchase_date";
    if (header === "nome_dell_acquirente") header = "buyer_name";
    if (header === "quantit_") header = "quantity_shipped";
    if (header === "prezzo_finale") header = "item_price";
    if (header === "cap_di_spedizione") header = "cap";
    if (header === "paese_dell_acquirente") header = "country";
    if (header === "email_dell_acquirente") header = "email";
    if (header === "etichetta_personalizzata") header = "sku";

    return header;
  }

  replaceWooHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_").replace(" ", "_");
    if (header === "order_number") header = "order_id";
    if (header === "order_date") header = "purchase_date";
    if (header === "product_current_price") header = "item_price";
    if (header === "quantity") header = "quantity_shipped";
    if (header === "postcode__shipping_") header = "cap";
    if (header === "country_code__shipping_") header = "country_code";
    if (header === "email__billing_") header = "email";

    return header;
  }

  replaceAliHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_").replace(" ", "_");
    if (header === "order_id") header = "order_id";
    if (header === "order_time") header = "purchase_date";
    if (header === "product_total") header = "item_price";
    if (header === "quantity") header = "quantity_shipped";
    if (header === "zip_code") header = "cap";
    if (header === "receiver_name") header = "buyer_name";
    if (header === "product_code") header = "sku";

    return header;
  }

  replaceProdottiHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_").replace(" ", "_");
    if (header === "codice_univoco_mlb") header = "uid";
    if (header === "marchio") header = "brand";
    if (header === "nome_") header = "name";
    return header;
  }
  render() {
    return (
      <div>
        <Container>
          {this.renderCSVLoader()}
          {this.renderCSVGiacenzeLoader()}
          {this.renderCSVVendorLoader()}
          {this.renderCSVCouponLoader()}
          {this.renderRiepilogoModal()}
          {this.renderRiepilogProdottiModal()}
          {this.renderRiepilogCouponModal()}
          {this.renderRiepilogAmazonFiscaleModal()}
          {this.renderFiscaleModalNotFound()}
        </Container>
      </div>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DReportCaricamento))));
